import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCbSdBG4xndIvo9RAFj_pmeLWKE5NveA5I",
  authDomain: "react-todo-app-38baf.firebaseapp.com",
  databaseURL: "https://react-todo-app-38baf.firebaseio.com",
  projectId: "react-todo-app-38baf",
  storageBucket: "react-todo-app-38baf.appspot.com",
  messagingSenderId: "408588599539",
  appId: "1:408588599539:web:d0330ee27b64795bd9b786",
  measurementId: "G-GXH8QFGK0Q",
});

const db = firebaseApp.firestore();
const provider = new firebase.auth.GoogleAuthProvider();
const auth = firebase.auth();

export { db, auth, provider };
